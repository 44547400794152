<template>
    <div class="relative width_100_percent height_100_percent" style="margin-bottom: 100px">
        <div style="height: 50px; width: 100%"></div>
        <div class="mt_50 flex align_center justify_center form_item" style="position: fixed; bottom: 0; right: 35px; z-index: 100; width: 85%; height: 60px; background: #f5f7f9; display: flex; justify-content: center; align-items: center">
            <i-button type="primary" ghost @click="onSubmit" style="" v-if="isDetail != 'true'">保存</i-button>
            <i-button type="primary" ghost @click="onBack" style="margin-left: 10px">返回</i-button>
        </div>
        <!-- <Tabs style="width: 84%; background: #fff" @on-click="toTabs"> -->
            <!-- <TabPane :label="item.class_title" :name="item.id" v-for="item in tabList" :key="item.id"> -->
                <div v-for="info in check_note" :key="info.id">
                    <!-- <div style="height: 50px; width: 100%"></div> -->
                    <Card class="mt_15" :title="itemName.title" v-for="itemName in info.list" :key="itemName.id">
                        <!-- <div class="relative flex flex_wrap align_start">
                <div class="form_item" v-for="subInfo in info.list" :key="subInfo.id">
                    <template v-if="subInfo.type === 'upload'">
                        <div class="form_label">{{ subInfo.title }}</div>
                        <div class="width_200"><previewImage :thumWidth="60" :thumHeight="60" :src="subInfo.value"></previewImage></div>
                    </template>
                    <template v-else-if="subInfo.type === 'uploadMultiple'">
                        <div class="form_label">{{ subInfo.title }}</div>
                        <div class="width_200 flex flex_wrap align_center">
                            <previewImage v-for="(src, idx) in !!subInfo.value ? subInfo.value.split(',') : []" :class="{ ml_10: idx > 0 }" :key="encodeURIComponent(src)" :thumWidth="60" :thumHeight="60" :src="src"></previewImage>
                        </div>
                    </template>
                    <template v-else>
                        <div class="form_label">{{ subInfo.title }}</div>
                        <div class="width_200 break_all">{{ subInfo.value }}</div>
                    </template>
                </div>
            </div> -->
                        <div>
                            <div v-if="isDetail == 'true'">
                                <div v-for="item2 in itemName.list" :key="item2.id">
                                    <div v-if="item2.type == 'text' || item2.type == 'textarea'" style="display: flex">
                                        <div style="margin: 15px 15px">{{ item2.title }}:</div>
                                        <div style="margin: 15px 15px">{{ item2.value }}</div>
                                    </div>
                                    <div v-if="item2.type == 'upload'">
                                        <div class="form_item">
                                            <!-- <div class="form_label align_self_start must_input">图片</div> -->
                                            <div>
                                                <div class="relative upload_panel" v-if="item2.value">
                                                    <img style="width: 100px; height: 100px" :src="item2.value" />
                                                    <!-- <UploadImage :disabled="true" :width="100" :height="100" v-model="item2.value" @success="onSuccessUploadImg(item2)" @remove="onRemoveImg"></UploadImage> -->
                                                </div>
                                                <div v-else>暂无图片</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Form :model="formLeft" label-position="left" :label-width="100" v-else>
                                <FormItem :label="item2.title" v-for="item2 in itemName.list" :key="item2.id">
                                    <Input v-if="item2.type == 'text'" :placeholder="item2.placeholder" v-model="item2.value" style="width: 800px"></Input>
                                    <Input type="textarea" maxlength="item2.maxlength" v-if="item2.type == 'textarea'" :placeholder="item2.placeholder" v-model="item2.value" style="width: 800px"></Input>
                                    <div v-if="item2.type == 'upload'">
                                        <div class="form_item">
                                            <div class="form_label align_self_start must_input">图片</div>
                                            <div>
                                                <div class="relative upload_panel">
                                                    <UploadImage :width="100" :height="100" v-model="item2.value" @success="onSuccessUploadImg(item2)" @remove="onRemoveImg"></UploadImage>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <Upload
                                            :data="formData"
                                            ref="upload"
                                            :show-upload-list="false"
                                            :default-file-list="defaultList"
                                            :on-success="handleSuccess"
                                            :format="['jpg', 'jpeg', 'png']"
                                            :max-size="2048"
                                            :on-format-error="handleFormatError"
                                            :on-exceeded-size="handleMaxSize"
                                            :before-upload="handleBeforeUpload"
                                            multiple
                                            type="drag"
                                            :action="actions"
                                            style="display: inline-block; width: 58px"
                                        >
                                            <div style="width: 58px; height: 58px; line-height: 58px">
                                                <Icon type="ios-camera" size="20"></Icon>
                                            </div>
                                        </Upload>
                                        <ImagePreview v-model="visible" :preview-list="['https://file.iviewui.com/images/' + imgName]" /> -->
                                    </div>
                                    <div v-if="item2.type === 'uploadMultiple' || item2.type=== true  ">
                                        <div class="form_item">
                                            <div>
                                                <div class="relative upload_panel">
                                                    <!-- <div class="form_label">{{ item2.title }}</div> -->
                                                    <div class="width_200 flex flex_wrap align_center">
                                                        <previewImage v-for="(src, idx) in !!item2.value ? item2.value.split(',') : []" :class="{ ml_10: idx > 0 }" :key="encodeURIComponent(src)" :thumWidth="60" :thumHeight="60" :src="src"></previewImage>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <Upload
                                            :data="formData"
                                            ref="upload"
                                            :show-upload-list="false"
                                            :default-file-list="defaultList"
                                            :on-success="handleSuccess"
                                            :format="['jpg', 'jpeg', 'png']"
                                            :max-size="2048"
                                            :on-format-error="handleFormatError"
                                            :on-exceeded-size="handleMaxSize"
                                            :before-upload="handleBeforeUpload"
                                            multiple
                                            type="drag"
                                            :action="actions"
                                            style="display: inline-block; width: 58px"
                                        >
                                            <div style="width: 58px; height: 58px; line-height: 58px">
                                                <Icon type="ios-camera" size="20"></Icon>
                                            </div>
                                        </Upload>
                                        <ImagePreview v-model="visible" :preview-list="['https://file.iviewui.com/images/' + imgName]" /> -->
                                    </div>
                                </FormItem>
                            </Form>
                        </div>
                    </Card>
                </div>
            <!-- </TabPane> -->
        <!-- </Tabs> -->

        <Card class="mt_20" title="检测人员信息">
            <div class="form_item">
                <div class="form_label">测试员姓名</div>
                <!-- <Input class="width_300" v-model.trim="username" :maxlength="10" show-word-limit /> -->
                {{ accout_nickname }}
            </div>
            <div class="form_item">
                <div class="form_label">检测时间</div>
                <!-- <myDate v-model="jiancetime"></myDate> -->
                {{ ctime }}
            </div>
        </Card>
        <Card class="mt_20" title="更改记录">
            <div v-for="(item, index) in modify_logs" :key="index" style="border-bottom: solid #e8eaec 1px;margin-top: 20px;">
                <div class="form_item">
                    <div class="form_label">更改时间</div>
                    <!-- <Input class="width_300" v-model.trim="username" :maxlength="10" show-word-limit /> -->
                    {{ item.created_at }}
                </div>
                <div class="form_item">
                    <div class="form_label">更改人员</div>
                    <!-- <myDate v-model="jiancetime"></myDate> -->
                    {{ item.operator }}
                </div>
                <div class="form_item">
                    <div class="form_label">更改内容</div>
                    <!-- <myDate v-model="jiancetime"></myDate> -->
                    {{ item.content }}
                </div>
            </div>
        </Card>
        <div style="height: 100px"></div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </div>
</template>
<script>
import UploadImage from '@/components/uploadImage';
import uploadImageMultiple from '@/components/uploadImageMultiple';

import myDate from '@/components/myDate';
import { reqCheckView, geteditForm, saveReservation } from '@/lib/request/auth';
import previewImage from '@/components/previewImage';
import { consoleJson } from '@/lib/util';

export default {
    name: 'monitorManagerBillDetail',
    components: {
        UploadImage,
        myDate,
        previewImage,
        uploadImageMultiple
    },
    data() {
        return {
            arrPic: [''],
            isEdit: false,
            formData: {
                upfun: 'admin',
            },
            actions: window.location.origin + '/upload/commonUpload/ajax_up',
            defaultList: [
                {
                    name: 'image-demo-1.jpg',
                    url: 'https://file.iviewui.com/images/image-demo-1.jpg',
                },
                {
                    name: 'image-demo-2.jpg',
                    url: 'https://file.iviewui.com/images/image-demo-2.jpg',
                },
            ],
            imgName: '',
            visible: false,
            uploadList: [],
            formLeft: {},
            tabList: [
            ],
            id: null,
            list: [],
            username: null,
            jiancetime: null,
            accout_nickname: null,
            ctime: null,
            check_note: [],
            file: null,
            pic: '',
            loadingStatus: false,
            isDetail: '',
            modify_logs: [],
            zid:'',
            zindex:0
        };
    },
    mounted() {
        console.log(this.isDetail, 'this.isDetail');
        this.id = this.$route.query.id;
        this.zid = this.$route.query.zid;
        this.isDetail = this.$route.query.isDetail;
        this.routeIdInject();
        this.getDetail();
    },
    methods: {
        //单个图片上传成功
        onSuccessUploadImg(row) {
            console.log(row, 'row');
            if (this.arrPic.length < this.maxLength && hm.isNotEmptyString(this.arrPic.slice(-1)[0])) {
                this.arrPic.push('');
            } else if (this.arrPic.length <= 0) {
                this.arrPic.push('');
            }
        },
        //删除某张图片
        onRemoveImg() {
            this.arrPic = this.arrPic.filter(src => !!src);
            this.onSuccessUploadImg();
            this.arrPic.push(0);
            this.arrPic.pop();
        },
        toTabs(name) {
            console.log(name, 'eeee');
        },
        onSubmit() {
            var needData = {};
            needData.data = this.tabList;
            saveReservation({ data: this.tabList }).then(res => {
                this.fadeAlert('保存成功');
                this.$router.back();
            });
        },
        handleView(name) {
            this.imgName = name;
            this.visible = true;
        },
        handleRemove(file) {
            const fileList = this.$refs.upload.fileList;
            this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
        },
        handleSuccess(res, file) {
            console.log(file, 'file');
            console.log(res, 'res');

            // file.url = 'https://file.iviewui.com/images/image-demo-3.jpg';
            // file.name = 'image-demo-3.jpg';
        },
        handleFormatError(file) {
            this.$Notice.warning({
                title: 'The file format is incorrect',
                desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.',
            });
        },
        handleMaxSize(file) {
            this.$Notice.warning({
                title: 'Exceeding file size limit',
                desc: 'File  ' + file.name + ' is too large, no more than 2M.',
            });
        },
        handleBeforeUpload() {
            const check = this.uploadList.length < 5;
            if (!check) {
                this.$Notice.warning({
                    title: 'Up to five pictures can be uploaded.',
                });
            }
            return check;
        },
        handleUpload(file) {
            this.file = file;
            return false;
        },
        upload() {
            this.loadingStatus = true;
            setTimeout(() => {
                this.file = null;
                this.loadingStatus = false;
                this.$Message.success('Success');
            }, 1500);
        },
        //获取详情数据
        getDetail() {
            // this.showAjaxLoading();
            // reqCheckView({ id: this.id })
            //     .then(res => {
            //         //检测单详情
            //         console.log('####################################### reqCheckView res=', res);
            //         let { check_note, accout_nickname, ctime } = res?.data || {};
            //         let note = JSON.parse(check_note || '[]');
            //         let noteArr = note.map(info => info.list) || [];
            //         noteArr = noteArr.reduce((prev, curr) => prev.concat(curr), []);
            //         this.list = noteArr;

            //         this.accout_nickname = accout_nickname;
            //         this.ctime = new Date(+ctime * 1000).Format('yyyy-MM-dd HH:mm:ss');

            //         consoleJson(noteArr);
            //     })
            //     .finally(() => {
            //         this.hideAjaxLoading();
            //     });
            geteditForm({ re_id: this.id }).then(res => {
                this.tabList = res.data;
                res.data.forEach((item,index)=>{
                    if(this.zid == item.id){
                        this.zindex = index;
                    }
                })
                this.check_note = res.data[this.zindex].check_note;
                this.modify_logs = res.data[this.zindex].modify_logs;
                this.ctime = new Date(+res.data[0].ctime * 1000).Format('yyyy-MM-dd HH:mm:ss');
                this.accout_nickname = res.data[0].accout_nickname;
                // this.ctime = res.data.ctime;
            });
        },
        //返回
        onBack() {
            this.$router.back();
        },
    },
};
</script>
<style lang="less" scoped>
.form_item {
    &.bill {
        width: 400px;
    }
    .form_label {
        align-self: flex-start;
    }
}
</style>
